<template>
  <div class="d-md-flex flex-row">
    <!-- begin::Aside -->
    <div class="flex-row-auto w-100 w-md-300px w-xl-350px">
      <div class="card card-custom">
        <div class="card-body pt-4">
          <!-- begin::Profile -->
          <div class="d-flex align-items-center">
            <div class="symbol symbol-50 mr-5 align-self-start align-self-xxl-center symbol-light-primary">
              <div class="symbol-label font-size-h5 font-weight-bold">
                <template v-if="resource.initials">{{ resource.initials }}</template>
                <template v-else>
                  <font-awesome-icon :icon="['fas', 'user']" />
                </template>
              </div>
            </div>
            <div>
              <span class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">{{ (resource.name || resource.email) }}</span>
            </div>
          </div>
          <!-- end::Profile -->

          <!-- begin::Navigation -->
          <div class="navi navi-bold navi-hover navi-active navi-link-rounded pt-5">
            <div class="navi-item mb-2">
              <router-link to="/settings/personal/information" active-class="active" class="navi-link py-4">
                <span class="navi-icon mr-4">
                  <span class="svg-icon">
                    <font-awesome-icon :icon="['fas', 'info-circle']" class="h-20px w-20px" />
                  </span>
                </span>
                <span class="navi-text font-size-lg">Information</span>
              </router-link>
            </div>

            <div class="navi-item mb-2">
              <router-link to="/settings/personal/authentication" active-class="active" class="navi-link py-4">
                <span class="navi-icon mr-4">
                  <span class="svg-icon">
                    <font-awesome-icon :icon="['fas', 'lock']" class="h-20px w-20px" />
                  </span>
                </span>
                <span class="navi-text font-size-lg">Authentication</span>
              </router-link>
            </div>

            <div class="navi-item mb-2">
              <router-link to="/settings/personal/notifications" active-class="active" class="navi-link py-4">
                <span class="navi-icon mr-4">
                  <span class="svg-icon">
                    <font-awesome-icon :icon="['fas', 'bell']" class="h-20px w-20px" />
                  </span>
                </span>
                <span class="navi-text font-size-lg">Notifications</span>
              </router-link>
            </div>

            <!-- begin::API -->
            <template v-if="$auth.hasPermission('user_personal_tokens.get')">
              <li class="navi-separator my-0" />

              <div class="navi-item mb-2">
                <router-link to="/settings/personal/tokens" active-class="active" class="navi-link py-4">
                  <span class="navi-icon mr-4">
                    <span class="svg-icon">
                      <font-awesome-icon :icon="['fas', 'code']" class="h-20px w-20px" />
                    </span>
                  </span>
                  <span class="navi-text font-size-lg">API tokens</span>
                </router-link>
              </div>
            </template>
            <!-- end::API -->
          </div>
          <!-- end::Navigation -->
        </div>
      </div>
    </div>
    <!-- end::Aside -->

    <!-- begin::Content -->
    <div class="flex-row-fluid mt-8 mt-md-0 ml-md-8">
      <router-view
        ref="router-view"
        :resource="resource"
      />
    </div>
    <!-- end::Content -->
  </div>
</template>

<script>
export default {
  data () {
    return {
      resource: this.$auth.resource,
    };
  },
  mounted () {
    this.$metronic.breadcrumbs.setBreadcrumbs([{ title: 'Settings' }, { title: 'Personal Settings' }]);
  },
};
</script>
